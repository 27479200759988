<script>
  export let direction = 'down'
  export let color = 'var(--font-normal)'
  export let scale = 1
</script>

<style>
  .caret.up {
    transform: rotate(180deg);
  }

  .caret.left {
    transform: rotate(90deg);
  }

  .caret.right {
    transform: rotate(270deg);
  }
</style>

<svg
  width={11 * scale}
  height={6 * scale}
  viewBox="0 0 11 6"
  xmlns="http://www.w3.org/2000/svg"
  class="caret"
  class:up={direction === 'up'}
  class:left={direction === 'left'}
  class:right={direction === 'right'}
  style:fill={color}
>
  <path
    d="M.504 1.813l4.358 3.845.496.438.496-.438 4.642-4.096L9.504.438 4.862 4.534h.992L1.496.69.504 1.812z"
  />
</svg>
